import React from "react";
import styled from "styled-components";

import AngularIcon from "img/portfolio/icon-angular.jpg";
import CovoteIcon from "img/portfolio/icon-covote.jpg";
import FirebaseIcon from "img/portfolio/icon-firebase.jpg";
import FlexerIcon from "img/portfolio/icon-flexer.jpg";
import IntervidIcon from "img/portfolio/icon-intervid.jpg";
import IonicIcon from "img/portfolio/icon-ionic.jpg";
import MysqlIcon from "img/portfolio/icon-mysql.jpg";
import TitaniumIcon from "img/portfolio/icon-titanium.jpg";
import CovoteImages from "img/portfolio/portfolio-covote.jpg";
import FlexerImages from "img/portfolio/portfolio-flexer.jpg";
import IntervidImages from "img/portfolio/portfolio-intervid.jpg";

import HomeContainer from "./HomeContainer";

const PortfolioView = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  border-bottom: 1px #eee solid;
  padding: 40px 0;

  @media (max-width: 960px) {
    display: block;
  }
`;

const PortfolioAppName = styled.p`
  display: flex;
  align-items: center;
  font-size: 1.7rem;
  margin: 1rem 0;
`;
const PortfolioAppIcon = styled.img`
  height: 48px;
  width: 48px;
  margin-right: 10px;
`;

const PortfolioAppImage = styled.img`
  width: 100%;
  max-width: 700px;
`;

const PortfolioViewLeft = styled.div`
  flex: 1;
`;

const PortfolioViewRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
`;

const PortfolioAppTech = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    display: flex;
    float: left;
    align-items: center;
    font-size: 1.2rem;
    margin-right: 10px;
  }
  img {
    width: 32px;
    height: 32px;
  }
`;

const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const TitleBarImg = styled.div`
  height: 200px;
`;
const TitleBarHeader = styled.div`
  background: linear-gradient(
    135deg,
    rgba(51, 51, 51, 0.2) 0%,
    rgba(0, 0, 0, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  flex: 1;
  height: 101px;
`;

const Clear = styled.div`
  clear: both;
`;

const HomePortfolio: React.FC = () => {
  return (
    <HomeContainer>
      <PortfolioView>
        <PortfolioViewLeft>
          <PortfolioAppName>
            <PortfolioAppIcon src={IntervidIcon} />
            Intervid
          </PortfolioAppName>
          <p>
            Intervid is a mobile and web video interview platform. Save time on
            your hiring process, interview everyone at the same time. Users can
            create a interview with a title and and list of questions that will
            be answered by short video responses.
          </p>
          <PortfolioAppTech>
            <li>
              <img src={AngularIcon} /> Angular
            </li>
            <li>
              <img src={IonicIcon} /> Ionic
            </li>
            <li>
              <img src={FirebaseIcon} /> Firebase
            </li>
          </PortfolioAppTech>
          <Clear />
          <p>
            <strong>24</strong> App Design Hours - <strong>185</strong> App
            Development Hours
          </p>
        </PortfolioViewLeft>
        <PortfolioViewRight>
          <PortfolioAppImage src={IntervidImages} />
        </PortfolioViewRight>
      </PortfolioView>

      <PortfolioView>
        <PortfolioViewLeft>
          <PortfolioAppName>
            <PortfolioAppIcon src={CovoteIcon} />
            Covote
          </PortfolioAppName>
          <p>
            Intervid is a mobile and web video interview platform. Save time on
            your hiring process, interview everyone at the same time. Users can
            create a interview with a title and and list of questions that will
            be answered by short video responses.
          </p>
          <PortfolioAppTech>
            <li>
              <img src={AngularIcon} /> Angular
            </li>
            <li>
              <img src={TitaniumIcon} /> Titanium
            </li>
            <li>
              <img src={MysqlIcon} /> MySQL
            </li>
          </PortfolioAppTech>
          <Clear />
          <p>
            <strong>27</strong> App Design Hours - <strong>235</strong> App
            Development Hours
          </p>
        </PortfolioViewLeft>
        <PortfolioViewRight>
          <PortfolioAppImage src={CovoteImages} />
        </PortfolioViewRight>
      </PortfolioView>

      <PortfolioView>
        <PortfolioViewLeft>
          <PortfolioAppName>
            <PortfolioAppIcon src={FlexerIcon} />
            Flexer
          </PortfolioAppName>
          <p>
            Flexer is a social mobile app designed to connect workout
            enthusiasts with each other to share their latest workout efforts
            and progress. With Flexer you can select from popular workouts,
            indicate the weight you lifted and the number of sets and reps.
          </p>
          <PortfolioAppTech>
            <li>
              <img src={AngularIcon} /> Angular
            </li>
            <li>
              <img src={IonicIcon} /> Ionic
            </li>
            <li>
              <img src={FirebaseIcon} /> Firebase
            </li>
          </PortfolioAppTech>
          <Clear />
          <p>
            <strong>13</strong> App Design Hours - <strong>90</strong> App
            Development Hours
          </p>
        </PortfolioViewLeft>
        <PortfolioViewRight>
          <PortfolioAppImage src={FlexerImages} />
        </PortfolioViewRight>
      </PortfolioView>
    </HomeContainer>
  );
};

export default HomePortfolio;
