import React from "react";
import styled from "styled-components";

import BotBackground from "img/BotBackground.svg";

const FooterDiv = styled.div`
  z-index: 5;
  position: relative;
`;

const FooterTop = styled.div`
  background: transparent url(${BotBackground}) repeat-x;
  background-size: 100px 50px;
  height: 50px;
`;

const FooterBot = styled.div`
  background: #333;
  padding: 3rem 0;
  color: #fff;
  text-align: center;
`;

const HomeFooter: React.FC = () => {
  return (
    <FooterDiv>
      <FooterTop />
      <FooterBot>&copy; {new Date().getFullYear()} MVP Bee</FooterBot>
    </FooterDiv>
  );
};

export default HomeFooter;
