import React from "react";
import styled from "styled-components";

interface FormControlComponentProps {
  error?: string;
}

const FormControlComponent = styled.div<FormControlComponentProps>`
  display: flex;
  margin: 1rem 0;
  flex-direction: column;
  ${props =>
    props.error &&
    `
    color: red;
    input {
      border-color: red;
      color: red;
    }
  `};
`;

const FormControl: React.FC<FormControlComponentProps> = ({
  children,
  error,
  ...props
}) => (
  <FormControlComponent error={error} {...props}>
    {children}
    {error && <div>{error}</div>}
  </FormControlComponent>
);

export default FormControl;
