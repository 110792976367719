import React from "react";
import styled from "styled-components";

import { COLOR_YELLOW } from "utils/colors";

interface InputProps {}

const InputComponent = styled.input`
  flex: 1;
  border: 2px #333 solid;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  font-size: 16px;
  &:hover,
  &:active,
  &:focus {
    box-shadow: inset 0 0 0 2px ${COLOR_YELLOW};
  }
  &:disabled {
    background: rgba(80, 80, 80, 0.1);
  }
`;

const Input: React.FC<InputProps> = ({ ...props }) => (
  <InputComponent autoComplete={"off"} {...props} />
);

export default Input;
