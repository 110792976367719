import styled from "styled-components";

const ContainerComponent = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  min-height: 100vh;
  border-left: 1px #dddddd solid;
  border-right: 1px #dddddd solid;
  box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.2);
  @media (max-width: 480px) {
    width: 100%;
    border: none;
    box-shadow: none;
  }
`;

const Container = ContainerComponent;

export default Container;
