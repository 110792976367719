import React, { useState } from "react";
import styled from "styled-components";

import HomeContactForm from "components/home/HomeContactForm";
import HomeContainer from "components/home/HomeContainer";
import HomeHeading from "components/home/HomeHeading";
import HomePortfolio from "components/home/HomePortfolio";
import HomeProcess from "components/home/HomeProcess";

import {
  EVENT_ACTION_ELEMENT_CLICKED,
  EVENT_CATEGORY_PAGE_INT,
  EVENT_LABEL_PHONE_NUMBER
} from "utils/constants";
import { triggerGaEvent } from "utils/event";

const LargeText = styled.p`
  color: #666666;
  font-size: 24px;
  @media (max-width: 960px) {
    font-size: 18px;
  }
  @media (max-width: 720px) {
    font-size: 18px;
  }
`;

const ContactDetails = styled.div`
  &:last-child {
    padding: 20px 0 40px 0;
  }
  font-size: 32px;
  i.fa {
    font-size: 48px;
    margin-right: 10px;
  }
  a,
  span {
    display: flex;
    justify-content: center;
    color: #333;
    text-decoration: none;
    &:hover {
      color: #666;
    }
  }
`;
const ContactDetail = styled.div`
  margin-bottom: 20px;
`;

const HomeContent: React.FC = () => {
  const [number, setNumber] = useState("04-- --- ---");

  const showNumber = () => {
    const numbers = [[0, 2, 4, 0], [5, 0, 8], [0, 1, 7]];
    setNumber(numbers.map(set => set.reverse().join("")).join(" "));
    triggerGaEvent(
      EVENT_CATEGORY_PAGE_INT,
      EVENT_ACTION_ELEMENT_CLICKED,
      EVENT_LABEL_PHONE_NUMBER,
      1
    );
  };

  return (
    <HomeContainer>
      <HomeHeading name="mvp">What is an MVP?</HomeHeading>
      <LargeText>
        A Minimum Viable Product is the earliest version of your idea, with just
        enough features to attract your first users.
      </LargeText>
      <LargeText>
        If your idea is about getting people from point A to B, your minimum
        viable product would be more like a skateboard than a supercar.
      </LargeText>
      <HomeHeading name="process">The Process</HomeHeading>
      <HomeProcess />
      <HomeHeading name="work">Recent Projects</HomeHeading>
      <HomePortfolio />
      {/* <HomeHeading name="pricing">Pricing</HomeHeading>
      <PricingContent /> */}
      {/* <HomeHeading name="packages">Packages</HomeHeading>
      <PackagesContent /> */}
      <HomeHeading name="contact">Contact Us</HomeHeading>
      <ContactDetails>
        <ContactDetail>
          <a href="mailto:info@mvpbee.com" target={"_blank"}>
            <i className="fa fa-envelope-square" /> info@mvpbee.com
          </a>
        </ContactDetail>
        <ContactDetail>
          <a href="javascript:;" onClick={showNumber}>
            <i className="fa fa-phone-square" /> <span>{number}</span>
          </a>
        </ContactDetail>
      </ContactDetails>
      <HomeContactForm />
    </HomeContainer>
  );
};

export default HomeContent;
