import ProcessDesign from 'img/ProcessDesign.svg';
import ProcessDevelopment from 'img/ProcessDevelopment.svg';
import ProcessIdea from 'img/ProcessIdea.svg';
import React from 'react';
import styled from 'styled-components';

const ProcessContent = styled.div`
  padding: 40px 0;
  @media (max-width: 960px) {
    padding: 0;
  }
`;

const ProcessContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: -40px 0;
  @media (max-width: 960px) {
    margin: 0 auto 40px auto;
    padding-bottom: 40px;
    border-bottom: 1px #eee solid;
    display: block;
  }
`;

const ProcessText = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 22px;
  color: #666666;
  justify-content: center;
  order: 1;
  h3 {
    color: #333333;
    font-size: 28px;
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 800;
  }

  span {
    color: #999999;
    font-size: 18px;
  }
`;

const ProcessTextLeft = styled(ProcessText)`
  text-align: right;
  margin-right: 20px;
  @media (max-width: 960px) {
    text-align: center;
  }
`;

const ProcessTextRight = styled(ProcessText)`
  text-align: left;
  margin-left: 20px;
  order: 2;
  @media (max-width: 960px) {
    text-align: center;
  }
`;

const ProcessImg = styled.div`
  flex: 1;
  order: 2;
`;

const HomeProcess: React.FC = () => {
  return (
    <ProcessContent>
      <ProcessContainer>
        <ProcessImg>
          <img src={ProcessIdea} />
        </ProcessImg>
        <ProcessTextLeft>
          <h3>Idea Discussion</h3>
          <p>
            We discuss your idea together and decide which features are required
            in the inital release of your app.
          </p>
          <span>2-4 hours</span>
        </ProcessTextLeft>
      </ProcessContainer>

      <ProcessContainer>
        <ProcessImg>
          <img src={ProcessDesign} />
        </ProcessImg>
        <ProcessTextRight>
          <h3>App Design</h3>
          <p>
            We design the different screens that will make up your app. After
            you have approved the designs, we move onto development.
          </p>
          <span>10-20 hours</span>
        </ProcessTextRight>
      </ProcessContainer>

      <ProcessContainer>
        <ProcessImg>
          <img src={ProcessDevelopment} />
        </ProcessImg>
        <ProcessTextLeft>
          <h3>App Development</h3>
          <p>
            Creating a mobile app requires programming thousands of lines of
            code and each line of code requires testing the app still works.
          </p>
          <span>100-200+ hours</span>
        </ProcessTextLeft>
      </ProcessContainer>
    </ProcessContent>
  );
};

export default HomeProcess;
