import styled from 'styled-components';

const LabelComponent = styled.label`
  display: block;
  margin: 0.5rem 0;
  font-weight: 800;
`;

const Label = LabelComponent;

export default Label;
