import * as Yup from "yup";

import Input from "components/form/Input";
import OptionGroup, { OptionProps } from "components/form/OptionGroup";

const platformOptions: OptionProps[] = [
  { label: "Mobile", value: "mobile" },
  { label: "Desktop", value: "desktop" }
];

const budgetOptions: OptionProps[] = [
  { label: "$1-5k", value: "1-5" },
  { label: "$5-10k", value: "5-10" },
  { label: "$10-20k", value: "10-20" },
  { label: "$20k+", value: "20+" }
];

const FORM_FIELD_NAME = "name";
const FORM_FIELD_CONTACT = "contact";
const FORM_FIELD_PLATFORM = "platform";
const FORM_FIELD_BUDGET = "budget";

export default {
  [FORM_FIELD_NAME]: {
    label: "Name",
    initialValue: "",
    component: Input,
    props: {
      placeholder: "Enter your name"
    }
  },
  [FORM_FIELD_CONTACT]: {
    label: "Contact",
    initialValue: "",
    component: Input,
    props: {
      placeholder: "Enter an email or phone number"
    },
    required: true,
    validation: Yup.string().required()
  },
  // [FORM_FIELD_PLATFORM]: {
  //   label: "Platform",
  //   initialValue: "",
  //   component: OptionGroup,
  //   props: {
  //     options: platformOptions,
  //     multiple: true
  //   }
  // },
  [FORM_FIELD_BUDGET]: {
    label: "Budget",
    initialValue: "",
    component: OptionGroup,
    props: {
      direction: "column",
      options: budgetOptions
    }
  }
};
