import React, { ReactElement, useState } from "react";
import firebase from "firebase";

import { EVENT_ACTION_LP_SUBMIT, EVENT_CATEGORY_LP } from "utils/constants";
import { triggerGaEvent } from "utils/event";

const firestore = firebase.firestore();

interface DataSubmissionProps {
  children: (data: any) => ReactElement;
  data?: any;
  collection: string;
  label: string;
}

const DataSubmission: React.FC<DataSubmissionProps> = ({
  children,
  collection,
  data,
  label
}) => {
  const [complete, setComplete] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const onSubmit = (values: any) => {
    setSubmitting(true);
    return Promise.resolve()
      .then(() =>
        firestore.collection(collection).add({
          ...values,
          ...data
        })
      )
      .then(() => {
        setSubmitting(false);
        setComplete(true);
        triggerGaEvent(EVENT_CATEGORY_LP, EVENT_ACTION_LP_SUBMIT, label, 1);
      })
      .catch(err => {
        console.error(err);
        setSubmitting(false);
        setComplete(false);
      });
  };
  return children({ complete, submitting, onSubmit });
};

export default DataSubmission;
