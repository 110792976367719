import HomeContainer from 'components/home/HomeContainer';
import HomeTopBar from 'components/home/HomeTopBar';
import HomeTopPhone from 'components/home/HomeTopPhone';
import React from 'react';
import styled from 'styled-components';

const H2 = styled.h2`
  font-size: 3.5rem;
  font-weight: 300;
  transition: font-size 100ms linear;
  strong {
    font-weight: 800;
  }
  @media (max-width: 960px) {
    font-size: 2.5rem;
  }
`;
const H3 = styled.h3`
  font-size: 1.6rem;
  color: #444;
  font-weight: 300;
  transition: font-size 100ms linear;
  margin-bottom: 3rem;

  @media (max-width: 960px) {
    font-size: 1.7rem;
  }
`;

const TopViewDiv = styled.div`
  background: #fff075;
`;

const TopView: React.FC = () => {
  return (
    <TopViewDiv>
      <HomeContainer>
        <HomeTopBar />
        <H2>
          Every startup once had a<br />
          <strong>Minimum Viable Product.</strong>
        </H2>
        <H3>
          We help you turn your startup idea
          <br />
          into an Android and iPhone app.
        </H3>
      </HomeContainer>
      <HomeTopPhone />
    </TopViewDiv>
  );
};

export default TopView;
