import React from "react";
import styled from "styled-components";

const TopBarNavDiv = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 960px) {
    display: none;
  }
`;

const TopBarNavItem = styled.div`
  margin: 10px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  a {
    padding: 10px 20px;
    color: #333333;
    text-decoration: none;
    font-weight: 800;
    border-radius: 5px;
    transition: background 100ms linear;

    &:hover {
      background: rgba(255, 255, 255, 0.7);
    }
  }
`;

const TopBarNav: React.FC = () => {
  return (
    <TopBarNavDiv>
      <TopBarNavItem>
        <a href="#mvp">What</a>
      </TopBarNavItem>
      <TopBarNavItem>
        <a href="#process">Process</a>
      </TopBarNavItem>
      <TopBarNavItem>
        <a href="#work">Work</a>
      </TopBarNavItem>
      <TopBarNavItem>
        <a href="#contact">Contact</a>
      </TopBarNavItem>
    </TopBarNavDiv>
  );
};

export default TopBarNav;
