import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCsBlRG4l-WzG7KiwADkVNW0AL34p0vRWA",
  authDomain: "mvp-bee.firebaseapp.com",
  databaseURL: "https://mvp-bee.firebaseio.com",
  projectId: "mvp-bee",
  storageBucket: "mvp-bee.appspot.com",
  messagingSenderId: "122304634477",
  appId: "1:122304634477:web:6950eaf3ed34d8b2"
};

firebase.initializeApp(firebaseConfig);
