import React from "react";
import styled from "styled-components";

import TopBarNav from "components/home/TopBarNav";

import BeeImg from "img/Bee.svg";

const TopBar = styled.div`
  height: 60px;
  padding: 20px 0 0 0;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Logo = styled.img`
  height: 60px;
`;

const HomeTopBar: React.FC = () => {
  return (
    <TopBar>
      <Logo src={BeeImg} alt="MVP Bee Logo" />
      <TopBarNav />
    </TopBar>
  );
};

export default HomeTopBar;
