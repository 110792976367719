import TagManager from "react-gtm-module";

import { EVENT_GA_EVENT } from "utils/constants";

export const triggerEvent = (event: string, data?: object) => {
  try {
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...data
      }
    });
  } catch (e) {}
};

export const triggerGaEvent = (
  eventCategory: string,
  eventAction?: string,
  eventLabel?: string,
  eventValue?: number
) => {
  triggerEvent(EVENT_GA_EVENT, {
    eventCategory,
    eventAction,
    eventLabel,
    eventValue
  });
};
