import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import { Col, Grid, Row } from "react-flexbox-grid";
import FontAwesome from "react-fontawesome";
import { RouteComponentProps } from "react-router";
import { FormikConfig } from "formik";

import BotSection from "components/BotSection";
import CheckList from "components/CheckList";
import Container from "components/Container";
import Content from "components/Content";
import DataSubmission from "components/form/DataSubmission";
import FormikForm from "components/form/FormikForm";
import LandingPage from "components/LandingPage";
import TopSection from "components/TopSection";

import { FORM_LP_GREENFIELD } from "utils/constants";

import Bee from "img/Bee.svg";
import Logo from "img/Logo.png";
import Phone from "img/Phone.png";

import GreenfieldLandingPageForm from "forms/GreenfieldLandingPageForm";

const TopLogo = styled.img`
  height: 100px;
  width: 200px;
`;

const TopBee = styled.img`
  height: 80px;
`;

const TopPhone = styled.img`
  position: absolute;
  height: 200px;
`;

const TopContent = styled.div`
  padding: 2rem 2rem 1rem 2rem;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const RightCol = styled(Col)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

const TextP = styled.p`
  font-size: 20px;
`;

const checklistItems = [
  "Low Risk",
  "High Reward",
  "Great Price",
  "No Interruptions",
  "Local Developers"
];

const extraData = {
  form: FORM_LP_GREENFIELD
};

const FacebookLandingPage: React.FC<RouteComponentProps> = ({
  location,
  history
}) => {
  useEffect(() => {
    history.push(location.pathname.replace(/\/thankyou/g, ""));
  }, []);
  return (
    <LandingPage>
      <Container>
        <TopSection>
          <TopContent>
            <Grid>
              <Row>
                <Col xs={8}>
                  <FlexDiv>
                    <a href="/">
                      <TopLogo src={Logo} />
                      <TopBee src={Bee} />
                    </a>
                  </FlexDiv>
                  <div>Web &amp; Mobile App Developers</div>
                  <div>
                    <FontAwesome name={"map-marker-alt"} /> Brisbane, Australia
                  </div>
                </Col>
                <RightCol xs={4}>
                  <TopPhone src={Phone} />
                </RightCol>
              </Row>
            </Grid>
          </TopContent>
        </TopSection>
        <Content>
          <DataSubmission
            collection={"contact"}
            label={FORM_LP_GREENFIELD}
            data={extraData}
          >
            {({ complete, submitting, onSubmit }) => {
              const formSubmit: FormikConfig<any>["onSubmit"] = (
                values: any,
                { setSubmitting }
              ) => {
                setSubmitting(true);
                onSubmit(values).then(() => {
                  setSubmitting(false);
                  history.push(`${location.pathname}/thankyou`);
                });
              };
              if (complete) {
                return (
                  <Fragment>
                    <h2>Thankyou</h2>
                    <TextP>
                      Thanks for taking the time to get in touch with us.
                    </TextP>
                    <TextP>
                      If you would like to find out more about us please visit
                      the website: <a href="/">https://mvpbee.com</a>.
                    </TextP>
                  </Fragment>
                );
              }
              return (
                <Grid>
                  <Row>
                    <Col xs={12} md={6}>
                      <h2>Greenfield project</h2>
                      <TextP>
                        Does your company need a software project developed,
                        without diverting resources from your current tech team?
                      </TextP>
                      <TextP>
                        This is the perfect solution to experimenting with ideas
                        without disrupting your current operation.
                      </TextP>
                      <CheckList items={checklistItems} />
                      <TextP>
                        Tell us what you need and we will get in touch with you
                        through your preferred contact method.
                      </TextP>
                    </Col>
                    <Col xs={12} md={6}>
                      <h2>Get in touch</h2>
                      <FormikForm
                        formData={GreenfieldLandingPageForm}
                        onSubmit={formSubmit}
                      />
                    </Col>
                  </Row>
                </Grid>
              );
            }}
          </DataSubmission>
        </Content>
        <BotSection>
          <br />
          <br />
        </BotSection>
      </Container>
    </LandingPage>
  );
};

export default FacebookLandingPage;
