import React, { FormEvent } from "react";
import FontAwesome from "react-fontawesome";
import { Field, FieldProps, Form, Formik, FormikConfig } from "formik";

import FormControl from "components/form/FormControl";
import Label from "components/form/Label";
import SubmitButton from "components/form/SubmitButton";

import { getInitialValues, getValidationSchema } from "utils/form";

import Required from "./Required";

interface FormikFormProps {
  formData: any;
  onSubmit: FormikConfig<any>["onSubmit"];
}

const FormikForm: React.FC<FormikFormProps> = ({ formData, onSubmit }) => {
  const initialValues = getInitialValues(formData);
  const validationSchema = getValidationSchema(formData);
  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors, validateForm }) => (
        <Form>
          {Object.keys(formData).map(fieldName => {
            const fieldData = formData[fieldName];
            const {
              component: FieldComponent,
              props,
              label,
              required
            } = fieldData;
            return (
              <Field
                name={fieldName}
                key={fieldName}
                render={({ field }: { field: FieldProps["field"] }) => (
                  <FormControl error={errors[fieldName]}>
                    <Label>
                      {label} {required && <Required />}
                    </Label>
                    <FieldComponent
                      {...props}
                      {...field}
                      disabled={isSubmitting}
                      onChange={(e: FormEvent<any>) => {
                        field.onChange(e);
                        errors[fieldName] && validateForm();
                      }}
                      onBlur={(e: FormEvent<any>) => {
                        field.onBlur(e);
                        errors[fieldName] && validateForm();
                      }}
                    />
                  </FormControl>
                )}
              />
            );
          })}
          <SubmitButton disabled={isSubmitting}>
            {isSubmitting ? <FontAwesome name="spinner" spin /> : "Submit"}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

export default FormikForm;
