import React from "react";
import styled from "styled-components";

const RequiredComponent = styled.span`
  color: red;
`;

const Required = () => <RequiredComponent>*</RequiredComponent>;

export default Required;
