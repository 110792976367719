import React from "react";
import styled from "styled-components";

import { COLOR_YELLOW } from "utils/colors";

import TopImgSvg from "img/TopBackground.svg";

const TopSectionComponent = styled.div`
  background: ${COLOR_YELLOW};
`;

const TopSectionImage = styled.div`
  background-color: white;
  background-image: url(${TopImgSvg});
  background-repeat: repeat-x;
  height: 50px;
`;

const TopSection: React.FC = ({ children }) => (
  <TopSectionComponent>
    {children}
    <TopSectionImage />
  </TopSectionComponent>
);

export default TopSection;
