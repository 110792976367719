import { Component } from "react";
import * as Yup from "yup";

export type InitialValuesProps = {
  [key: string]: string;
};

export type ValidationSchemaProps = {
  [key: string]: any;
};

export type FormDataProps = {
  [fieldName: string]: {
    component: Component;
    props: {
      [propsName: string]: any;
    };
    placeholder: string;
    label: string;
    initialValue: any;
    validation: any;
  };
};

export const getInitialValues = (formData: FormDataProps) => {
  const initialValues: InitialValuesProps = {};
  for (let fieldName in formData) {
    const field = formData[fieldName];
    const { initialValue } = field;
    initialValues[fieldName] = initialValue;
  }
  return initialValues;
};

export const getValidationSchema = (formData: FormDataProps) => {
  const validationSchema: ValidationSchemaProps = {};
  for (let fieldName in formData) {
    const field = formData[fieldName];
    const { validation } = field;
    if (validation) {
      validationSchema[fieldName] = validation;
    }
  }
  return Yup.object().shape(validationSchema);
};
