import React from "react";
import styled from "styled-components";
import FontAwesome from "react-fontawesome";

import { COLOR_YELLOW } from "utils/colors";

const Box = styled(FontAwesome)`
  color: black;
`;

const Check = styled(FontAwesome)`
  color: ${COLOR_YELLOW};
`;

const Checkmark: React.FC = ({ ...props }) => (
  <span className={"fa-stack fa-xs"} {...props}>
    <Box name={"square"} stack={"2x"} />
    <Check name={"check"} stack={"1x"} />
  </span>
);

export default Checkmark;
