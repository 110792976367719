import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";

import "utils/firebase";

import App from "./components/App";
import "./index.css";

const tagManagerArgs = {
  gtmId: "GTM-K83VDJT"
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById("root"));
