import React from "react";
import styled from "styled-components";

import BotImgSv from "img/BotBackground.svg";

const TopSectionComponent = styled.div`
  background: #333333;
`;

const TopSectionImage = styled.div`
  background-color: white;
  background-image: url(${BotImgSv});
  background-repeat: repeat-x;
  height: 50px;
`;

const TopSection: React.FC = ({ children }) => (
  <TopSectionComponent>
    <TopSectionImage />
    {children}
  </TopSectionComponent>
);

export default TopSection;
