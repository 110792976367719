import styled from "styled-components";

const HomeContainer = styled.div`
  max-width: 1100px;
  padding: 0 80px;
  text-align: left;
  margin: 0 auto;
  @media (max-width: 960px) {
    text-align: center;
    padding: 0 40px;
  }
`;

export default HomeContainer;
