import React from 'react';
import styled from 'styled-components';

import Checkmark from './Checkmark';

interface CheckListProps {
  items: string[];
}

const CheckListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckListItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
`;

const CheckListItemSpan = styled.span`
  margin-left: 0.4rem;
  font-weight: 600;
  font-size: 18px;
`;

const CheckList: React.FC<CheckListProps> = ({ items }) => (
  <CheckListContainer>
    {items.map(item => (
      <CheckListItem key={item}>
        <Checkmark />
        <CheckListItemSpan>{item}</CheckListItemSpan>
      </CheckListItem>
    ))}
  </CheckListContainer>
);

export default CheckList;
