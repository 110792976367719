import React, { ChangeEvent, ComponentType, FormEvent } from "react";
import styled from "styled-components";
import { Field, FieldProps } from "formik";

import { COLOR_YELLOW, COLOR_YELLOW_LIGHT } from "utils/colors";

export interface OptionProps {
  value: string;
  label: string | ComponentType;
}

export interface OptionGroupProps {
  name?: string;
  direction?: "row" | "column";
  options: OptionProps[];
  multiple?: boolean;
  value?: string | string[];
  disabled: boolean;
}

type OptionGroupComponentProps = {
  direction?: OptionGroupProps["direction"];
};

type OptionComponentProps = {
  direction?: OptionGroupProps["direction"];
  selected?: boolean;
  disabled?: boolean;
};

const OptionGroupComponent = styled.div<OptionGroupComponentProps>`
  display: flex;
  flex-direction: ${props => props.direction};
  justify-content: space-between;
`;

const OptionComponent = styled.label<OptionComponentProps>`
  display: flex;
  align-items: center;
  flex: 1;
  border: 2px #333 solid;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  transition: all 100ms linear;
  margin: ${props => (props.direction === "row" ? "0 1rem 0 0" : "0 0 1rem 0")};
  background: ${props => (props.selected ? COLOR_YELLOW_LIGHT : "transparent")};
  line-height: 16px;
  font-size: 16px;
  cursor: pointer;
  &:last-child {
    margin: 0;
  }
  &:hover {
    box-shadow: inset 0 0 0 2px
      ${props => (props.selected ? "white" : COLOR_YELLOW)};
  }
  ${props =>
    props.disabled &&
    `
    background: rgba(80, 80, 80, 0.1);
  `}
`;

const OptionInput = styled(Field)`
  height: 16px;
  width: 16px;
  margin: 0 0.4rem 0 0;
`;

const OptionGroup: React.FC<OptionGroupProps & FieldProps["field"]> = ({
  name,
  disabled,
  direction = "row",
  options,
  multiple,
  value,
  onChange,
  onBlur,
  ...props
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }
    const { currentTarget } = event;
    if (multiple) {
      const valueArray = [...value] || [];
      if (currentTarget.checked) {
        valueArray.push(currentTarget.value);
      } else {
        valueArray.splice(valueArray.indexOf(currentTarget.value), 1);
      }
      onChange(name)({ target: { value: valueArray } });
    } else {
      onChange(event);
    }
  };
  const handleBlur = (event: FormEvent) => {
    onBlur(event);
  };
  const isSelected = (optionValue: string) => {
    if (multiple) {
      return value.indexOf(optionValue) > -1;
    } else {
      return value === optionValue;
    }
  };
  return (
    <OptionGroupComponent direction={direction} {...props}>
      {options.map(option => {
        const id = `${name}_${option.value}`;
        const selected = isSelected(option.value);
        return (
          <OptionComponent
            key={id}
            htmlFor={id}
            direction={direction}
            selected={selected}
            disabled={disabled}
          >
            <OptionInput
              id={id}
              type={multiple ? "checkbox" : "radio"}
              name={name}
              checked={selected}
              value={option.value}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <span>{option.label}</span>
          </OptionComponent>
        );
      })}
    </OptionGroupComponent>
  );
};

export default OptionGroup;
