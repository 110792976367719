export const EVENT_GA_EVENT = "GA_EVENT";

export const EVENT_CATEGORY_LP = "Landing Page";
export const EVENT_CATEGORY_CONTACT = "Contact Form";
export const EVENT_CATEGORY_PAGE_INT = "Page Interaction";

export const EVENT_ACTION_LP_SUBMIT = "Landing Page Submit";
export const EVENT_ACTION_CONTACT_SUBMIT = "Contact Form Submit";
export const EVENT_ACTION_ELEMENT_CLICKED = "Element Clicked";

export const EVENT_LABEL_PHONE_NUMBER = "Phone Number";

export const FORM_LP_GREENFIELD = "Greenfield Project";
