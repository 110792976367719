import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import GreenfieldLandingPage from "components/pages/GreenfieldLandingPage";
import HomePage from "components/pages/HomePage";
import PrivacyPage from "components/pages/PrivacyPage";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/contact/greenfield" component={GreenfieldLandingPage} />
        <Route path="/privacy" component={PrivacyPage} />
        <Route path="/" component={HomePage} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
