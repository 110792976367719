import React, { Fragment } from "react";

import HomeContent from "components/home/HomeContent";
import HomeFooter from "components/home/HomeFooter";
import HomeTop from "components/home/HomeTop";

const HomePage = () => {
  return (
    <Fragment>
      <HomeTop />
      <HomeContent />
      <HomeFooter />
    </Fragment>
  );
};

export default HomePage;
