import styled from "styled-components";

const ContentComponent = styled.div`
  flex: 1;
  padding: 2rem;
`;

const Content = ContentComponent;

export default Content;
