import React from "react";
import styled from "styled-components";

import { COLOR_YELLOW, COLOR_YELLOW_LIGHT } from "utils/colors";

interface SubmitButtonProps {
  disabled: boolean;
}

const SubmitButtonComponent = styled.button`
  flex: 1;
  border-top: 3px rgba(255, 255, 255, 0.6) solid;
  border-left: 3px rgba(255, 255, 255, 0.6) solid;
  border-right: 3px rgba(0, 0, 0, 0.1) solid;
  border-bottom: 3px rgba(0, 0, 0, 0.1) solid;
  padding: 0.8rem 2rem;
  font-size: 1.2em;
  border-radius: 5px;
  background: ${COLOR_YELLOW};
  box-shadow: 0 0 0 2px #333333;
  margin: 0 auto;
  transition: all 100ms linear;
  cursor: pointer;
  width: 100%;
  &:hover {
    background: ${COLOR_YELLOW_LIGHT};
    color: #333333;
  }
  &:active,
  &:disabled {
    background: ${COLOR_YELLOW};
    opacity: 0.5;
    border-top: 3px rgba(0, 0, 0, 0.1) solid;
    border-left: 3px rgba(0, 0, 0, 0.1) solid;
    border-right: 3px rgba(255, 255, 255, 0.6) solid;
    border-bottom: 3px rgba(255, 255, 255, 0.6) solid;
  }
`;

const SubmitButton: React.FC<SubmitButtonProps> = ({ children, ...props }) => (
  <SubmitButtonComponent {...props} type={"submit"}>
    {children}
  </SubmitButtonComponent>
);

export default SubmitButton;
