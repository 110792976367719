import firebase from 'firebase';
import { Field, Form, Formik, FormikConfig } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import { EVENT_ACTION_CONTACT_SUBMIT, EVENT_CATEGORY_CONTACT } from 'utils/constants';
import { triggerGaEvent } from 'utils/event';
import * as Yup from 'yup';

const ContactFormContainer = styled.div`
  margin: 3rem auto -100px auto;
  padding: 30px 60px;
  background: #eeeeee;
  border-radius: 5px;
  padding-bottom: 120px;
  width: 600px;
  transition: width 100ms linear;
  @media (max-width: 960px) {
    width: 80%;
    border-radius: 0;
  }
`;

const ContactFormSubmitted = styled.div`
  margin: 40px 0;
  border: 2px #333 solid;
  padding: 40px;
  margin: 10px;
  border-radius: 10px;
  font-size: 1.5rem;
  color: #333;
`;
const ContactFormText = styled.div`
  position: relative;
  background: #fff;
  padding: 10px 20px;
`;
const ContactFormError = styled.div`
  font-weight: 800;
  color: #f00;
  text-align: left;
  margin-top: 10px;
`;
const ContactForm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 2;
`;

const ContactFormField = styled.div`
  margin: 20px 0;
  text-align: center;

  label {
    display: block;
    text-align: left;
    margin-right: 20px;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 24px;
  }
  input,
  textarea {
    border: none;
    background: transparent;
    border-bottom: 2px #aaa solid;
    transition: border 150ms linear;
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    margin: 10px 0 0 0;
    resize: none;
    font-family: "Lato";
    &::placeholder {
      color: #aaa;
    }
    &:focus {
      border-bottom: 4px #333 solid;
    }

    &:disabled {
      color: #ccc;
    }
  }
  button {
    background: #333;
    color: #fff;
    border: none;
    padding: 15px 60px;
    font-size: 24px;
    border-radius: 5px;
    transition: background 100ms linear;
    margin: 2rem auto 0 auto;
    cursor: pointer;
    &:hover {
      background: #444;
    }
    &:disabled {
      background: #888;
      cursor: default;
    }
  }
`;

const initialValues = {
  name: "",
  email: "",
  mobile: "",
  idea: ""
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().required()
});

const FORM_NAME = "Homepage Contact";

const HomeContactForm: React.FC = () => {
  const [complete, setComplete] = useState(false);
  const onSubmit: FormikConfig<any>["onSubmit"] = (
    values: any,
    { setError, setSubmitting }
  ) => {
    const { name, email, mobile, idea } = values;
    return firebase
      .firestore()
      .collection("contact")
      .add({
        name,
        email,
        mobile,
        idea,
        form: FORM_NAME
      })
      .then(() => {
        triggerGaEvent(
          EVENT_CATEGORY_CONTACT,
          EVENT_ACTION_CONTACT_SUBMIT,
          FORM_NAME,
          1
        );
        setSubmitting(false);
        setComplete(true);
      })
      .catch(err => {
        triggerGaEvent(
          EVENT_CATEGORY_CONTACT,
          EVENT_ACTION_CONTACT_SUBMIT,
          FORM_NAME,
          0
        );
        setSubmitting(false);
        setComplete(true);
        setError(err);
      });
  };
  if (complete) {
    return (
      <ContactForm>
        <ContactFormContainer>
          <ContactFormSubmitted>
            Thankyou, we have received your submission and will be in contact
            with you shortly.
          </ContactFormSubmitted>
        </ContactFormContainer>
      </ContactForm>
    );
  }
  return (
    <ContactForm>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, isSubmitting }) => (
          <ContactFormContainer>
            <Form>
              <ContactFormField>
                <label htmlFor="name">Name</label>
                <Field
                  name="name"
                  placeholder="First or full name"
                  autoComplete="off"
                  disabled={isSubmitting}
                />
                {errors["name"] && (
                  <ContactFormError>{errors["name"]}</ContactFormError>
                )}
              </ContactFormField>
              <ContactFormField>
                <label htmlFor="idea">Idea</label>
                <Field
                  name="idea"
                  placeholder="Briefly explain your startup idea"
                  autoComplete="off"
                  disabled={isSubmitting}
                />
                {errors["idea"] && (
                  <ContactFormError>{errors["idea"]}</ContactFormError>
                )}
              </ContactFormField>
              <ContactFormField>
                <label htmlFor="email">Email</label>
                <Field
                  name="email"
                  type="email"
                  placeholder="Email address"
                  autoComplete="off"
                  disabled={isSubmitting}
                />
                {errors["email"] && (
                  <ContactFormError>{errors["email"]}</ContactFormError>
                )}
              </ContactFormField>
              <ContactFormField>
                <label htmlFor="mobile">Mobile Number</label>
                <Field
                  name="mobile"
                  placeholder="Mobile Number"
                  autoComplete="off"
                  disabled={isSubmitting}
                />
                {errors["mobile"] && (
                  <ContactFormError>{errors["mobile"]}</ContactFormError>
                )}
              </ContactFormField>
              <ContactFormField>
                <button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <i className="fa fa-spinner fa-spin" />
                  ) : (
                    <span>Submit</span>
                  )}
                </button>
              </ContactFormField>
            </Form>
          </ContactFormContainer>
        )}
      </Formik>
    </ContactForm>
  );
};

export default HomeContactForm;
