import React from "react";
import styled from "styled-components";

const HomeHeadingDiv = styled.div`
  text-align: center;
  h2 {
    font-size: 48px;
    color: #333333;
    margin: 0 auto 10px auto;
  }
  margin: 80px auto 20px auto;
  @media (max-width: 960px) {
    margin-top: 40px;
    font-size: 42px;
  }
  @media (max-width: 720px) {
    font-size: 36px;
  }
`;
const HomeHeadingBorder = styled.div`
  width: 100px;
  height: 5px;
  background: #fff278;
  margin: 0 auto 20px auto;
`;

interface HomeHeadingProps {
  name: string;
}

const HomeHeading: React.FC<HomeHeadingProps> = ({ children, name }) => {
  return (
    <HomeHeadingDiv>
      <a id={name} />
      <h2>{children}</h2>
      <HomeHeadingBorder />
    </HomeHeadingDiv>
  );
};

export default HomeHeading;
