import React, { Fragment } from "react";
import styled from "styled-components";

import HomeContainer from "components/home/HomeContainer";

import { COLOR_YELLOW } from "utils/colors";

import HexagonImg from "img/Hexagon.svg";
import PhoneImg from "img/Phone.png";
import TopBackgroundImg from "img/TopBackground.svg";

const PhoneImgContainer = styled.div`
  position: relative;
  margin: 0 auto;
  z-index: 1;
  text-align: center;
`;
const PhoneBackgroundTop = styled.div`
  position: absolute;
  background: ${COLOR_YELLOW};
  top: 0;
  height: 60%;
  width: 100%;
  z-index: 2;
`;
const PhoneBackgroundBot = styled.div`
  position: absolute;
  background: white url(${TopBackgroundImg}) repeat-x;
  background-size: 100px 50px;
  background-position-y: top;
  bottom: 0;
  height: 40%;
  width: 100%;
  z-index: 2;
`;
const PhoneImgElement = styled.img`
  position: relative;
  max-width: 100%;
  margin-top: 20px;
  z-index: 3;
  margin: 0 auto;
  @media (min-width: 960px) {
    margin-left: 150px;
  }
`;

const Hexagons = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: row;
  margin-bottom: -150px;
  @media (max-width: 960px) {
    justify-content: center;
    margin-bottom: 20px;
  }
`;
const Hexagon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${HexagonImg}) no-repeat center;
  background-size: cover;
  width: 132px;
  height: 150px;
  font-size: 48px;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 960px) {
    width: 110px;
    height: 125px;
    font-size: 32px;
    justify-content: center;
    margin-bottom: 0px;
  }
`;

const HomeTopPhone: React.FC = () => {
  return (
    <Fragment>
      <HomeContainer>
        <Hexagons>
          <Hexagon>
            <i className={"fab fa-android"} />
          </Hexagon>
          <Hexagon>
            <i className={"fab fa-apple"} />
          </Hexagon>
        </Hexagons>
      </HomeContainer>
      <PhoneImgContainer>
        <PhoneImgElement src={PhoneImg} />
        <PhoneBackgroundTop />
        <PhoneBackgroundBot />
      </PhoneImgContainer>
    </Fragment>
  );
};

export default HomeTopPhone;
